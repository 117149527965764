// partners
import leda_health from "../assets/images/clients/leda-health.svg";
import tm from "../assets/images/clients/tech-mahindra.svg";
import e_transact from "../assets/images/clients/etranzact.svg";
import bc from "../assets/images/clients/bain-company.svg";
import lsef from "../assets/images/clients/lsetf.svg";
import mavenir from "../assets/images/clients/mavenir.svg";
import mtn from "../assets/images/clients/mtn.svg";
import cadg from "../assets/images/clients/cadg.svg";
import charles_araujo from "../assets/images/clients/charles-araujo.svg";
import gsm_systems from "../assets/images/clients/gsmsystems.svg";
import veriteks from "../assets/images/clients/veritek.svg";
import airtel from "../assets/images/clients/airtel.svg";
import cri2 from "../assets/images/clients/cri.svg";
import laspark from "../assets/images/clients/laspark.svg";
import chi from "../assets/images/clients/chi.svg";

// services
import service_one from "../assets/images/sections/service_one.jpg";
import service_two from "../assets/images/sections/service_two.jpg";
import service_three from "../assets/images/sections/service_three.jpg";

// icons
import icon_white from "../assets/images/icons/crest_white.png";
import down from "../assets/images/icons/down.svg";

// sections
import hunters from "../assets/images/sections/hunters.jpg";
import seekers from "../assets/images/sections/seekers.png";
import hero from "../assets/images/sections/hero.png";

// industries
import tech from "../assets/images/industries/tech.svg";
import finance from "../assets/images/industries/finance.svg";
import education from "../assets/images/industries/education.svg";
import hospitality from "../assets/images/industries/hospitality.svg";
import construction from "../assets/images/industries/construction.svg";
import pub from "../assets/images/industries/public.svg";
import energy from "../assets/images/industries/energy.svg";
import maritime from "../assets/images/industries/maritime.svg";
import manufacturing from "../assets/images/industries/manufacturing.svg";

// team
import teams_bg from "../assets/images/team/meet-team.png";

// testimonials
import bainCompany from "../assets/images/clients/bain-company.png";
import mw from "../assets/images/clients/m&w.png";
import gsm from "../assets/images/clients/gsm.png";
import cri from "../assets/images/clients/cri.png";
import etranzact from "../assets/images/clients/etranzact.png";
import elizabeth from "../assets/images/clients/elizabeth-a.png";
import deborah from "../assets/images/clients/deborah-a.png";
import chinyere from "../assets/images/clients/chinyere-l.png";

// bespoke
import discovery from "../assets/images/icons/discovery.svg";
import kickoff from "../assets/images/icons/kickoff.svg";
import precise from "../assets/images/icons/precise.svg";
import final from "../assets/images/icons/final.svg";
import check from "../assets/images/icons/check.svg";
import arrow from "../assets/images/icons/arrow.svg";

// leadership
import ememUsen from "../assets/images/team/emem-usen.jpg";
// import susan from '../assets/images/team/susan-iliyan.jpg';
import jairaj from "../assets/images/team/jairaj-suvarna.jpg";

// team members
import avatar from "../assets/images/team/avatar-photo.jpeg";

import twitter from "../assets/images/icons/twitter.svg";
import error404 from "../assets/images/icons/404.svg";

const db = {
  icon_black:
    "https://res.cloudinary.com/defwvlcup/image/upload/v1674636928/25thandstaffing/icon_black_yvmoug.png",
  icon_white: icon_white,
  hero: hero,
  seekers: seekers,
  hunters: hunters,
  user: "https://eu2.contabostorage.com/d6c5f85cb8c7411686db1028a11f1030:25thandstaffing/global/user.png",
  teams_bg: [teams_bg],
  twitter: twitter,
  arrow: arrow,
  error404: error404,
  down: down,

  partners: [
    cri2,
    veriteks,
    tm,
    chi,
    cadg,
    e_transact,
    lsef,
    bc,
    charles_araujo,
    gsm_systems,
    leda_health,
    mtn,
    laspark,
    mavenir,
    airtel,
  ],

  services: [service_one, service_two, service_three],

  processes: [
    {
      id: 1,
      title: "Tell us your needs",
      description:
        "Our Client relations officer will consult, advice and understand all your needs.",
    },
    {
      id: 2,
      title: "Sign contract/Agreement",
      description:
        "For the initiation of the selected recruitment product, contract terms and agreement will be defined and agreed upon.",
    },
    {
      id: 3,
      title: "Deposit/Advance Payment",
      description:
        "Based on the recruitment package opted for, a defined percentage of the total recruitment cost must be paid in advance.",
    },
    {
      id: 4,
      title: "We find the best candidates",
      description:
        "Based on selected package, we will send you 2-3 vetted candidates for selection.",
    },
  ],

  verticals: [
    { id: 1, title: "Tech & Telco", path: tech },
    { id: 2, title: "Finance & banking", path: finance },
    { id: 3, title: "Education", path: education },
    { id: 4, title: "Hospitality", path: hospitality },
    { id: 5, title: "Construction", path: construction },
    { id: 6, title: "Public sector", path: pub },
    { id: 7, title: "Energy", path: energy },
    { id: 8, title: "Maritime", path: maritime },
    { id: 9, title: "Manufacturing", path: manufacturing },
  ],

  testimonies: [
    {
      id: 1,
      content:
        "Based on the process and evaluation provided by your team, I must say it has been an exceptional and highly professional experience. I couldn't have asked for more.",
      name: "Tarek Khoury",
      company: "(Operations Manager - Erik Kayser, August 2023)",
      linkedin: "https://www.linkedin.com/in/tarek-khoury-500159115/",
      path: cri,
    },
    {
      id: 2,
      content:
        "Emem is truly professional and understood the complex nature of the role we were recruiting for and skill required quickly. The candidates Emem and her team sourced were of a high caliber.",
      name: "Michele de Kock",
      company: "(HR Manager, Africa - Bain & Company, July 2018)",
      linkedin: "",
      path: bainCompany,
    },
    {
      id: 3,
      content:
        "25th and Staffing have been providing skilled and professional human resource services to GSMSystems since 2016 and their performance have been very satisfactory meeting all our expectations.",
      name: "Gideon Itulua",
      company: "(Director - GSM Systems Africa, July 2018)",
      linkedin: "https://www.linkedin.com/in/gideon-itulua-b64064114",
      path: gsm,
    },
    {
      id: 4,
      content:
        "The company, 25th and Staffing, is well known to our firm. They have been our preferred recruiter for several years and have over time, consistently provided our company with a pool of diverse, talented and resourceful candidates regardless of the role.",
      name: "Abimbola Balogun",
      company: "(General Manager - M & W Nuts, September 2018)",
      linkedin: "https://www.linkedin.com/in/abimbola-balogun-3646042b",
      path: mw,
    },
    {
      id: 5,
      content:
        "25th and Staffing Limited has been our recruitment consultant/vendor, from January 2017 till date and they have delivered on all our agreed services. Most importantly their approach was very professional and they paid attention to detail on all our requests.",
      name: "Niyi Toluwalope",
      company: "(CEO - eTranzact, January 2017)",
      linkedin: "https://www.linkedin.com/in/niyi-toluwalope-9219611",
      path: etranzact,
    },
    {
      id: 6,
      content:
        "25th and Staffing Limited is our recruitment vendor, from January 2018 till date. I can confirm that we have enjoyed a remarkable working relationship, and that they have delivered nothing short of excellence in the services rendered.",
      name: "Adeboye Shonekan",
      company: "(Chairman - CRI, January 2018)",
      linkedin: "",
      path: cri,
    },
    {
      id: 7,
      content:
        "25th and Staffing has been engaged with Tech Mahindra for providing manpower and sourcing and outsourcing resources for right profile.",
      name: "Ayan Chatterjee",
      company: "(Finance, Africa - Tech Mahindra, October 2022)",
      linkedin: "https://www.linkedin.com/in/ayan-chatterjee-1209349/",
      path: cri,
    },
  ],

  testimoniesTalent: [
    {
      id: 1,
      content:
        "Working at Leda Health has been nothing short of amazing. The work culture is top-notch. I enjoy their transparency, honesty, and people-oriented approach. 25th and Staffing has been awesome since day one. Friendly and wonderful staff. Timely in delivery too 😊.",
      name: "Elizabeth Adeyemo (Leda Health Co)",
      path: elizabeth,
    },
    {
      id: 2,
      content:
        "Thanks to 25th and Staffing, I got interviewed with Sunbird Group and it's been a career - dream come true. The support I've gotten from 25th and Staffing every step of the way is nothing short of excellent. They were fully involved in the whole interview process, and have remained the ultimate professionals. I would definitely recommend 25th and Staffing to anyone looking for a job!",
      name: "Deborah Akugue (Sunbird)",
      path: deborah,
    },
    {
      id: 3,
      content:
        "It's a pleasure to share, the last 5 months have been beautiful for me, the experience working with Leda Health is really intriguing and I do appreciate 25th and Staffing for the opportunity to connect with End.",
      name: "Chinyere Linda (Leda Health Co)",
      path: chinyere,
    },
  ],

  bespoke: [
    {
      id: 1,
      title: "Discovery",
      path: discovery,
      content:
        "Deeply understands your needs, and create a plan for execution.",
    },
    {
      id: 2,
      title: "Kick Off",
      path: kickoff,
      content:
        "Conduct extensive research, create a profile of your ideal executive and create a pool of hand picked executive talent.",
    },
    {
      id: 3,
      title: "Precise Search Process",
      path: precise,
      content:
        "We conduct back to back interviews with highly-skilled, passionate executives.",
    },
    {
      id: 4,
      title: "Final Interview Stage",
      path: final,
      content:
        "You make your final selection, and we manage all recruitment and negotiation.",
    },
    {
      id: 5,
      title: "Search Conclusion",
      path: check,
      content:
        "You hire an experienced executive under an accelerated timeline.",
    },
  ],

  leadership: [
    { id: 1, path: ememUsen, name: "Emem Usen", jd: "CEO/Co-founder" },
    {
      id: 2,
      path: avatar,
      name: "Emmanuel Atsu",
      jd: "Chief Technical Officer",
    },
    {
      id: 3,
      path: jairaj,
      name: "Jairaj Suvarna",
      jd: "Independent Consultant",
    },
    // { id: 4, path: susan, name: 'Susan Iliyan', jd: 'Business Dev. Consultant' },
  ],

  teamMember: [
    { id: 1, path: avatar, name: "Omowunmi Felix", jd: "Accountant" },
    {
      id: 2,
      path: avatar,
      name: "Lawrence Jude",
      jd: "Talent Mgmt. Specialist",
    },
    // {
    //   id: 3,
    //   path: avatar,
    //   name: "Foster Ezennia",
    //   jd: "Talent Mgmt. Specialist",
    // },
    // {
    //   id: 3,
    //   path: avatar,
    //   name: "Funmilola Mobolaji",
    //   jd: "HR Generalist",
    // },
    {
      id: 3,
      path: avatar,
      name: "Isaac Bright",
      jd: "Talent Acquisition Specialist",
    },
    // {
    //   id: 4,
    //   path: avatar,
    //   name: "Christiana Nelson",
    //   jd: "Talent Acquisition Specialist",
    // },
    { id: 4, path: avatar, name: "Ebenezer Ndukwe", jd: "Frontend Developer" },
    // {
    //   id: 6,
    //   path: avatar,
    //   name: "Mathida Duku",
    //   jd: "Backend Developer",
    // },
    {
      id: 5,
      path: avatar,
      name: "Christian Monte",
      jd: "UI/UX Designer & Social Media Specialist",
    },
    {
      id: 6,
      path: avatar,
      name: "Anuoluwapo Sanni",
      jd: "IT Software Doc. & Support Specialist",
    },
    {
      id: 7,
      path: avatar,
      name: "Regina Ezeugo-Oha",
      jd: "Accounts Intern",
    },
    { id: 8, path: avatar, name: "Anabel Inah", jd: "Office Support" },
  ],
  teamMember2: [
    {
      id: 1,
      path: avatar,
      name: "Regina Ezeugo-Oha",
      jd: "Accounts Intern",
    },
    { id: 2, path: avatar, name: "Anabel Inah", jd: "Office Support" },
  ],

  faq: [
    {
      id: 1,
      title: "Internal Quality Check: We Practice What We Preach:",
      content:
        "We're Not Just Timekeepers; We're Time Masters. We adhere to timelines with the precision of a Swiss watch.",
    },
    {
      id: 2,
      title: "Reports That Speak Volumes:",
      content:
        "Our reports are like Sherlock Holmes novels - simple, detailed, and revealing personal and professional attributes, complete with the plot twists of pros and cons.",
    },
    {
      id: 3,
      title: "Proactive Communication is Our Superpower:",
      content:
        "We don't wait for the phone to ring; we make it sing.Proactive updates throughout the recruitment process are our way of staying ahead of the curve.",
    },
    {
      id: 4,
      title: "Tailored Services, Not One Size Fits All:",
      content:
        "No cookie-cutter solutions here. We tailor our services like bespoke suits, ensuring a perfect fit for each client's unique needs.",
    },
  ],
};

export default db;
